import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Form, Modal, Table, Pagination } from "react-bootstrap";

const AudioUpload = () => {
    const [file, setFile] = useState(null);
    const [title, setTitle] = useState("");
    const [artist, setArtist] = useState("");
    const [show, setShow] = useState(false);
    const [songs, setSongs] = useState([]);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);

    const handleFileChange = (e) => setFile(e.target.files[0]);
    const handleClose = () => setShow(false);

    // Fetch songs with pagination
    const fetchSongs = async (page = 1) => {
        const { data } = await axios.get(`http://localhost:5008/api/songs?page=${page}`);
        setSongs(data.songs);
        setTotalPages(data.totalPages);
        setPage(data.currentPage);
    };

    useEffect(() => {
        fetchSongs(page);
    }, [page]);

    // Handle submit to upload
    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append("file", file);
        formData.append("title", title);
        formData.append("artist", artist);

        try {
            const { data } = await axios.post("http://localhost:5008/api/upload", formData);
            alert("Upload successful!");
            fetchSongs(); // Refresh song list
        } catch (error) {
            console.error("Upload failed:", error);
        }

        // Reset form
        setFile(null);
        setTitle("");
        setArtist("");
        setShow(false);
    };

    return (
        <div>
            <div className="d-flex justify-content-between align-items-center">
                <h3>Music Panel</h3>
                <Button onClick={() => setShow(true)}>Add New Song</Button>
            </div>

            {/* Upload Modal */}
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Upload Music</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleSubmit}>
                        <Form.Group controlId="formFile" className="mb-3">
                            <Form.Label>Select Music File</Form.Label>
                            <Form.Control
                                type="file"
                                accept="audio/*"
                                onChange={handleFileChange}
                                required
                            />
                        </Form.Group>
                        <Form.Group controlId="formTitle" className="mb-3">
                            <Form.Label>Title</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter song title"
                                value={title}
                                onChange={(e) => setTitle(e.target.value)}
                                required
                            />
                        </Form.Group>
                        <Form.Group controlId="formArtist" className="mb-3">
                            <Form.Label>Artist</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter artist name"
                                value={artist}
                                onChange={(e) => setArtist(e.target.value)}
                                required
                            />
                        </Form.Group>
                        <Button variant="primary" type="submit">
                            Upload
                        </Button>
                    </Form>
                </Modal.Body>
            </Modal>

            {/* Songs Table */}
            <Table striped bordered hover className="mt-4">
                <thead>
                    <tr>
                        <th>Title</th>
                        <th>Artist</th>
                        <th>URL</th>
                    </tr>
                </thead>
                <tbody>
                    {songs.map((song, index) => (
                        <tr key={index}>
                            <td>{song.filename}</td>
                            <td>{song.artist}</td>
                            <td>
                                <a href={song.path} target="_blank" rel="noopener noreferrer">
                                    Listen
                                </a>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>

            {/* Pagination */}
            <Pagination>
                {[...Array(totalPages).keys()].map((pageNum) => (
                    <Pagination.Item
                        key={pageNum + 1}
                        active={pageNum + 1 === page}
                        onClick={() => setPage(pageNum + 1)}
                    >
                        {pageNum + 1}
                    </Pagination.Item>
                ))}
            </Pagination>
        </div>
    );
};

export default AudioUpload;
